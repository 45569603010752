import { useSearchParams } from 'next/navigation'
import pluralize from 'pluralize'
import { stringifyUrl } from 'query-string'
import { Fragment, useMemo } from 'react'
import styled from 'styled-components'

import { Animate } from '@syconium/little-miss-figgy/dist/components/Animate'
import { Skeleton } from '@syconium/little-miss-figgy/dist/components/Skeleton'
import { TypeStyle } from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'

import { NostoPlacementId } from '../../../app/_components/chrome/scripts/NostoScript'
import { NextLink } from '../../../app/_components/navigation/NextLink'
import {
  PageSection,
  PageSectionContent,
  sectionSideGutterFromMdAndExpanded,
  sectionSideGutterUntilMd,
} from '../../../app/_components/pages/PageSection.client'
import {
  PriceRangeForProductGroup,
  derivePricingForProductGroup,
} from '../../../app/_components/pricing/PriceRangeForProductGroup.client'
import {
  ProductTileColorCount,
  ProductTileColorName,
  ProductTileColors,
  ProductTileColorsSkeleton,
  ProductTileImage,
  ProductTileImageSkeleton,
  ProductTilePrice,
  ProductTilePriceSkeleton,
  ProductTileTitle,
  ProductTileTitleSkeleton,
} from '../../../app/_components/tiles/ProductTile.client'
import {
  LIFTED_BUTTONS_DEFAULT_PIXELS,
  Tile,
  TilesLayout,
} from '../../../app/_components/tiles/Tiles.client'
import { useTrackProductInteraction } from '../../../app/_providers/TrackingProvider.client'
import { useTranslation } from '../../../app/_providers/TranslationProvider.client'
import { trackEvent } from '../../../lib/analytics'
import { useNostoRecos } from '../../../lib/hooks/useNostoRecos'

const StyledTitle = styled(TypeStyle.HeadlineSmall)`
  margin-bottom: 16px;

  ${fromMd} {
    margin-bottom: 24px;
  }
`

export interface NostoProductRecommendationsSectionProps {
  analyticsContext?: {
    category?: string
  }
  fadeIn?: boolean
  nostoPlacementId: NostoPlacementId
  /**
   * Optionally override the Nosto-provided recommendation title.
   * Pass a string literal as a simple replacement, or pass a function that is
   * given the Nosto-provided recommendation title and returns a transformed
   * title.
   */
  title?: string | ((nostoTitle: string | undefined) => string | undefined)
}

export const NostoProductRecommendationsSection = ({
  analyticsContext,
  fadeIn,
  nostoPlacementId,
  title: titleOverride,
}: NostoProductRecommendationsSectionProps) => {
  const {
    magnolia: {
      productCarouselColorCount: colorCountText,
      general: { scrollBack: scrollBackLabel, scrollForward: scrollForwardLabel },
    },
  } = useTranslation()

  const { trackProductInteraction } = useTrackProductInteraction()

  const {
    placementTitle: nostoTitle,
    productSummaries: nostoProducts,
    resultId: nostoProductsId,
    status,
  } = useNostoRecos({
    nostoPlacementId,
    productCountLimit: 8,
  })

  // plain=true query param is used by iOS app to view webpages within a webview without the website's header/footer
  const plainQueryParamValue = useSearchParams()?.get('plain') ?? undefined
  const products = useMemo(() => {
    return nostoProducts.map(nostoProduct => {
      const basePath =
        nostoProduct.category === 'Product Kit'
          ? `/kits/${nostoProduct.productGroupHandle}`
          : `/products/${nostoProduct.productGroupHandle}?color=${nostoProduct.colorRawName}`

      return {
        ...nostoProduct,
        path: stringifyUrl({
          url: basePath,
          query: { nosto: nostoProductsId, plain: plainQueryParamValue },
        }),
        pricing: nostoProduct.price
          ? derivePricingForProductGroup({
              products: [
                {
                  priceRange: nostoProduct.priceRange,
                  defaultVariant: {
                    price: nostoProduct.price,
                    discountPrice: nostoProduct.discountPrice,
                  },
                },
              ],
            })
          : undefined,
      }
    })
  }, [nostoProducts, nostoProductsId, plainQueryParamValue])

  const title = useMemo<string | undefined>(() => {
    return typeof titleOverride === 'function'
      ? titleOverride(nostoTitle)
      : titleOverride ?? nostoTitle
  }, [nostoTitle, titleOverride])

  const tilesLayoutChoices = useMemo(() => {
    return {
      sm: 'slider',
      md: 'slider',
    } as const
  }, [])

  const tilesPerViewportWidth = useMemo(() => {
    return {
      sm: 2,
      md: 4,
    }
  }, [])

  const tilesGutterChoices = useMemo(() => {
    return {
      sm: sectionSideGutterUntilMd,
      md: sectionSideGutterFromMdAndExpanded,
    } as const
  }, [])

  const skeletonTiles = useMemo(() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [])

  const OptionalFadeIn = fadeIn ? Animate.FadeIn : Fragment

  if (status === 'rejected' || (status === 'resolved' && products.length === 0)) return null

  return (
    <PageSection>
      <PageSectionContent gutterSize='expanded'>
        <StyledTitle>
          {status === 'pending' ? <Skeleton animated height='42px' maxWidth='225px' /> : title}
        </StyledTitle>
      </PageSectionContent>
      <PageSectionContent gutterSize='none'>
        <OptionalFadeIn>
          <TilesLayout
            scrollBackLabel={scrollBackLabel}
            scrollForwardLabel={scrollForwardLabel}
            liftButtonsPixels={LIFTED_BUTTONS_DEFAULT_PIXELS}
            layout={tilesLayoutChoices}
            visibleTiles={tilesPerViewportWidth}
            gutters={tilesGutterChoices}
          >
            {status === 'pending'
              ? skeletonTiles.map((_, index) => {
                  return (
                    <Tile key={index}>
                      <ProductTileImageSkeleton aspectRatio={'slider'} />
                      <ProductTileTitleSkeleton />
                      <ProductTileColorsSkeleton />
                      <ProductTilePriceSkeleton />
                    </Tile>
                  )
                })
              : products.map((product, index) => {
                  if (!product.image) return null

                  return (
                    <Tile
                      key={`${product.id}${index}`}
                      {...trackEvent({
                        category: analyticsContext?.category ?? 'product-carousel-tile',
                        action: 'click tile',
                        label: `tile-${index + 1}`,
                        value: product.title,
                        correspondingAsset: product.image,
                      })}
                    >
                      <NextLink
                        href={product.path}
                        onClick={() => {
                          trackProductInteraction({
                            handle: product.handle,
                            id: product.id,
                            shopifyId: product.shopifyId,
                          })
                        }}
                      >
                        <ProductTileImage
                          alt={product.title}
                          loading='lazy'
                          src={product.image}
                          aspectRatio={'slider'}
                          widths={{
                            unit: 'vw',
                            sm: 100 / tilesPerViewportWidth.sm,
                            md: 100 / tilesPerViewportWidth.md,
                          }}
                        />
                        <ProductTileTitle>{product.title}</ProductTileTitle>
                        <ProductTileColors>
                          {product.colorRawName !== 'No Color' && (
                            <ProductTileColorName>{product.colorDisplayName}</ProductTileColorName>
                          )}
                          <ProductTileColorCount>
                            {product.colorCount} {pluralize(colorCountText, product.colorCount)}
                          </ProductTileColorCount>
                        </ProductTileColors>
                        {product.pricing ? (
                          <ProductTilePrice>
                            <PriceRangeForProductGroup
                              {...product.pricing}
                              upToPercentageOff={null}
                              percentageOff={null}
                            />
                          </ProductTilePrice>
                        ) : null}
                      </NextLink>
                    </Tile>
                  )
                })}
          </TilesLayout>
        </OptionalFadeIn>
      </PageSectionContent>
    </PageSection>
  )
}
