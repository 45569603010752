export const siteName = 'FIGS'

export const tagline = 'Awesome Humans'

export const canonicalBaseUrls = {
  collections: 'https://www.wearfigs.com/collections/',
  home: 'https://www.wearfigs.com/',
  kits: 'https://www.wearfigs.com/kits/',
  products: 'https://www.wearfigs.com/products/',
}

export const socialMediaHandles = {
  instagram: '@wearfigs',
  twitter: '@wearfigs',
  hashtag: 'wearfigs',
}

export const facebookAppID = '2608783789444248'

export const siteLinks = {
  helpLink: 'https://help.wearfigs.com',
  contactSupportLink: 'https://help.wearfigs.com/hc/en-us/requests/new',
}

export const relativePaths = {
  termsPath: '/pages/terms-of-use',
  privacyPath: '/pages/privacy',
  shopMensBestSellersPath: '/collections/best-sellers-mens',
  shopWomensBestSellersPath: '/collections/best-sellers-womens',
  page410Path: '/pages/410',
}

export const imageSrcs = {
  metaImage:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/FIGS_LOGO_BLACK-02-large.png?v=1574898420',
  notFound:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/ekg_4970b0c7-3fe4-4f12-8684-4e1d946935d6.gif?v=1636655846',
  pageGoneError:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/ekg_4970b0c7-3fe4-4f12-8684-4e1d946935d6.gif?v=1636655846',
  login: 'https://cdn.shopify.com/s/files/1/3101/9164/files/login-600x600-v2-1.jpg?v=1639073776',
  reset: 'https://cdn.shopify.com/s/files/1/3101/9164/files/login-600x600-v2-1.jpg?v=1639073776',
  activate: 'https://cdn.shopify.com/s/files/1/3101/9164/files/login-600x600-v2-1.jpg?v=1639073776',
}
