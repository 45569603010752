import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'

import { reportClientError } from '../../../app/_components/chrome/scripts/DataDogRumScript'
import { NostoPlacementId } from '../../../app/_components/chrome/scripts/NostoScript'
import { useFetchNostoPlacementData } from '../../../app/_hooks/useFetchNostoPlacementData'
import { degenderProductGroupTitle } from '../../../lib/utils'
import { CarouselProductSummary } from '../../../types/figs'
import { FETCH_RECO_PRODUCT_DETAILS_OPTIMIZED } from '../../graphql/queries/FETCH_RECO_PRODUCT_DETAILS_OPTIMIZED'
import { QueryStatus } from '../types'

import type { Product, ProductGroup } from '../../../types/graphql'

export function useNostoRecos({
  nostoPlacementId,
  productCountLimit,
  skip,
}: {
  nostoPlacementId: NostoPlacementId
  productCountLimit?: number
  skip?: boolean
}) {
  const [fetchRecoProductDetails, { data, error, called, loading }] = useLazyQuery<{
    optimizedProducts?: { nodes: readonly (Product & { productGroup: ProductGroup })[] }
  }>(FETCH_RECO_PRODUCT_DETAILS_OPTIMIZED, {
    onError: error => {
      reportClientError({
        error,
        context: {},
      })
    },
  })

  const {
    data: {
      productHandles: nostoRecommendedProductHandles,
      title: placementTitle,
      resultId: placementResultId,
    },
    status: nostoStatus,
  } = useFetchNostoPlacementData(nostoPlacementId, {
    skip: skip ?? false,
  })

  useEffect(() => {
    if (!skip && nostoRecommendedProductHandles && nostoRecommendedProductHandles.length > 0) {
      fetchRecoProductDetails({
        variables: { handles: nostoRecommendedProductHandles },
      })
    }
  }, [fetchRecoProductDetails, nostoRecommendedProductHandles, skip])

  const products = data?.optimizedProducts
  const productSummaries: readonly CarouselProductSummary[] = useMemo(() => {
    if (error) return []
    const allProductSummaries: CarouselProductSummary[] =
      products?.nodes.flatMap(p => {
        if (!p.defaultVariant) return []

        const summary: CarouselProductSummary = {
          category: p.category,
          colorRawName: p.colorInfo?.rawName,
          colorDisplayName: p.colorInfo?.name,
          colorCount: p.productGroup.colors.length,
          currency: p.defaultVariant.priceDetails.price.currency,
          discountPrice: p.defaultVariant.priceDetails.discountPrice?.amount,
          image: p.images?.nodes[0]?.source,
          price: p.defaultVariant.priceDetails.price.amount,
          priceRange: p.priceRange ?? undefined,
          productGroupHandle: p.productGroup.handle,
          title: p.productGroup.title ? degenderProductGroupTitle(p.productGroup.title) : '',
          id: p.id,
          handle: p.handle ?? '',
          shopifyId: p.shopifyId,
        }
        return summary
      }) ?? []
    return allProductSummaries.slice(0, productCountLimit ?? undefined)
  }, [products?.nodes, error, productCountLimit])

  const status = useMemo<Exclude<QueryStatus, 'idle'>>(() => {
    if (skip) return 'resolved'

    // We are waiting on Nosto JS
    if (nostoStatus === 'pending') return 'pending'

    // Failed
    if (error || nostoStatus === 'rejected') return 'rejected'

    // Notso came back with nothing for us
    if (!nostoRecommendedProductHandles || nostoRecommendedProductHandles.length === 0)
      return 'resolved'

    // Waiting on product details query
    if (!called || loading) return 'pending'

    return 'resolved'
  }, [called, error, loading, nostoRecommendedProductHandles, nostoStatus, skip])

  return {
    error,
    placementTitle,
    productSummaries,
    resultId: placementResultId,
    status,
  }
}
